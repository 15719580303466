<template>
    <modal ref="modalCrearProveedor" titulo="Crear proveedor" icon="proveedor" :cargado-modal="cargando" no-aceptar adicional="Guardar" no-cancelar cerrar tamano="modal-lg" @adicional="crearProveedor">
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col">
                    <div class="row mx-0 pb-3">
                        <div class="col-12 d-middle-center py-3">
                            <slim-cropper ref="fotoProveedor" :options="slimOptions" class="border cr-pointer cropper-proveedor" />
                        </div>
                        <div class="col">
                            <p class="pl-3 text-general  f-14">Nombre</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:25" name="nombre">
                                <el-input v-model="model.nombre" placeholder="Nombre del proveedor" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <p class="pl-3 text-general  f-14">NIT</p>
                            <ValidationProvider v-slot="{errors}" rules="max:20" name="NIT">
                                <el-input v-model="model.nit" placeholder="Ingresa el NIT" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 my-3">
                            <p class="pl-3 text-general  f-14">Dirección</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:100" name="dirección">
                                <el-input v-model="model.direccion" placeholder="dirección" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 my-2">
                            <p class="pl-3 text-general  f-14">Observaciones</p>
                            <ValidationProvider v-slot="{errors}" rules="max:750" name="observaciones">
                                <el-input v-model="model.observaciones" placeholder="Comentario del proveedor" type="textarea" :rows="3" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col my-2">
                            <ValidationProvider v-slot="{errors}" rules="required" name="país">
                                <p class="ml-2 text-general">País</p>
                                <el-select v-model="id_pais" class="w-100" filterable>
                                    <el-option
                                    v-for="item in paises"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col my-2">
                            <ValidationProvider v-slot="{errors}" rules="required" name="ciudad">
                                <p class="ml-2 text-general">Ciudad</p>
                                <el-select
                                v-model="model.idm_ciudad"
                                filterable
                                remote
                                clearable
                                class="w-100"
                                reserve-keyword
                                :remote-method="consultarCiudadesEstados"
                                >
                                    <!-- <el-select v-model="model.idm_ciudad" class="w-100" clearable filterable> -->
                                    <el-option
                                    v-for="item in ciudades"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-auto" />
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Localizaciones from "~/services/proyectos/localizaciones";
import Proveedor from "~/services/proveedores/proveedores";
export default {
    data(){
        return {
            model: {
                nombre: '',
                nit: '',
                logo: '',
                direccion: '',
                observaciones: '',
                idm_ciudad: null,
            },
            id_pais: null,
            cargando: false,
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            paises: [],
            ciudades: [],
        }
    },
    watch: {
        id_pais(){
            this.consultarCiudadesEstados('')
        }
    },
    methods: {
        toggle(){
            this.consultarPaises()
            this.$refs.modalCrearProveedor.toggle();
        },
        async consultarPaises(){
            try {
                const {data} = await Localizaciones.consultarPaises(this.$usuario.proyecto.id)
                this.paises = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarCiudadesEstados(query = ''){
            try {
                const params = {
                    proyecto: this.$usuario.proyecto.id,
                    pais: this.id_pais,
                    text: query,
                }
                const {data} = await Localizaciones.consultarCiudadesEstados(params)
                this.ciudades = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async crearProveedor(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.fotoProveedor.instanciaCrop.dataBase64.output
                this.model.logo = archivo.image

                this.cargando = true
                const { data } = await Proveedor.crearProveedor(this.model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$refs.modalCrearProveedor.toggle()
                this.$router.push({ name: 'admin.proveedores.ver', params :{ id_proveedor: data.model.id } })
            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        limpiar(){
            this.model = {
                nombre: '',
                nit: '',
                logo: '',
                direccion: '',
                observaciones: '',
                idm_ciudad: null,
            }
            this.$refs.fotoProveedor.instanciaCrop.remove()
        },
    }
}
</script>
<style lang="css" scoped>
.cropper-proveedor{
    height:162px;
    width:162px;
    background:#DBDBDB;
    border-radius:12px;
}
</style>
